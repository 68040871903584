<template>
  <button
    :disabled="disabled"
    :title="text"
    type="button"
    class="secondary-button button-secondary"
  >
    <span
      class="button-text"
      :class="{ redText: text === 'Удалить аккаунт и данные' }"
    >
      {{ text }}
    </span>
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.secondary-button {
  @apply
  flex
  rounded-full
  px-5
  py-2
  font-light
  shadow-sm
  hover:bg-gray-50
  bg-secondary-light
  text-primary-light
  dark:bg-secondary-dark
  dark:text-salat-text
}

.secondary-button:disabled {
  @apply
  text-gray-300
  bg-gray-50
  cursor-not-allowed
}

.button-text {
  @apply mr-2
}

.redText {
  @apply text-red-500
}
</style>